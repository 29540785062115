html {
  background: rgb(25, 25, 25);
}

body {
  /* !important
    Used to have text not use the text cursor by default.
    Typography uses 'inherit' to preserve UI input styles.
   */
  cursor: 'default';
  /* end !important */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  /* !important
    Used to create separate scrolling areas: nav and main
    but keep the header out of the scrolling context.
    This creates a smaller rendering context which helps with
    animation performance.
  */
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  /* end !important */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Used by DataTable column headers */
div[draggable='true'] {
  cursor: grab;
}

.flex {
  display: flex;
}

.flexCrossStart {
  align-items: flex-start;
}

.flexCrossCenter {
  align-items: center;
}

.flexCrossEnd {
  align-items: flex-end;
}

.flexMainBetween {
  justify-content: space-between;
}

.flexMainStart {
  justify-content: flex-start;
}

.flexMainCenter {
  justify-content: center;
}

.flexMainEnd {
  justify-content: flex-end;
}

.flexWrap {
  flex-wrap: wrap;
}

*::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  /* Height of horizontal scrollbars */
  height: 12px;
  width: 12px;
}

*::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  border-style: solid;
  border-radius: 12px;
  /* Creates padding around scroll thumb */
  border-width: 2px;
}

@media (prefers-color-scheme: light) {
  *::-webkit-scrollbar-corner,
  body::-webkit-scrollbar-corner,
  div::-webkit-scrollbar-corner {
    background: rgb(230, 230, 230);
  }

  *::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track,
  div::-webkit-scrollbar-track {
    background: rgb(230, 230, 230);
  }

  *::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: rgb(100, 100, 100);
    border-color: rgb(230, 230, 230);
  }
}

@media (prefers-color-scheme: dark) {
  *::-webkit-scrollbar-corner,
  body::-webkit-scrollbar-corner,
  div::-webkit-scrollbar-corner {
    background-color: rgb(54, 54, 54);
  }

  *::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track,
  div::-webkit-scrollbar-track {
    background-color: rgb(54, 54, 54);
  }

  *::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: rgb(30, 30, 30);
    border-color: rgb(54, 54, 54);
  }
}

/* Hides the up/down arrows on number fields */
input[type='number']::-webkit-inner-spin-button {
  display: none;
}

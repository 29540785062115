@keyframes slideInLeft {
  from {
    opacity: 0;
    margin-left: -30px;
  }

  to {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    margin-left: 30px;
  }

  to {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    margin-top: -35px;
  }

  to {
    opacity: 1;
    margin-top: -5px;
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    margin-top: 35px;
  }

  to {
    opacity: 1;
    margin-top: -5px;
  }
}

@keyframes highlightChatBubble {
  50% {
    border-color: #f9f9a8;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
